<template>
  <div>
    <div class="search-container">
      <el-row>
        <el-col :span="16">
          <el-form label-width="100px" :model="form">
            <el-row>
              <el-col :span="8">
                <el-form-item label="设备ID">
                  <el-input
                    v-model="form.deviceId"
                    clearable
                    placeholder="请输入设备ID"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户">
                  <el-input
                    v-model="form.username"
                    clearable
                    placeholder="请输入客户名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所属场馆">
                  <el-input
                    v-model="form.venueName"
                    clearable
                    placeholder="请输入所属场馆"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="验证时间">
                  <el-date-picker
                    style="width: 100%"
                    clearable
                    v-model="form.checkTime"
                    type="daterange"
                    format="yyyy - MM - dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="验证方式">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.checkType"
                    style="width: 100%"
                  >
                    <el-option label="全部" value=""></el-option>
                    <el-option
                      v-for="item in checkType"
                      :key="item.code"
                      :label="item.label"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="8" style="text-align: right; padding-right: 50px">
          <el-button @click="cleanForm" class="clean-button">清空</el-button>
          <el-button @click="searchEvent" class="search-button">查询</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        @sort-change="sort_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column
          prop="deviceId"
          align="center"
          label="设备Id"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="checkgateName"
          align="center"
          label="闸机名称"
          width="150"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="200"
          prop="venueName"
          label="场馆"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="username"
          min-width="100"
          label="客户"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="mobile"
          min-width="120"
          label="手机号码"
        >
        </el-table-column>
        <el-table-column
          align="center"
          min-width="150"
          prop="checkTime"
          show-overflow-tooltip
          label="验证时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="checkType"
          min-width="120"
          label="验证方式"
          :formatter="checkTypeFormatter"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="passageType"
          width="200"
          label="类型"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="operation">
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total"
          >
          </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/index";
import SearchClass from "./searchClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  data() {
    return {
      form: new SearchClass("form"),
      tableData: [],
      checkType: [],
    };
  },

  mounted() {},

  methods: {
    checkTypeFormatter(row, column, cellValue) {
      let string = ''
      if (cellValue === 'FACE') {
        string = '人脸识别'
      } else if (cellValue === 'CARD') {
        string = '实体卡'
      } else if (cellValue === 'QRCODE') {
        string = '二维码'
      }
      return string
    },
      //   清空搜索框
    cleanForm() {
      this.form = new SearchClass("form");
      this.searchEvent();
    },
    searchData() {
      const formData = { ...this.form };
      if (this.form.checkTime !== null && this.form.checkTime.length === 2) {
          formData.startTime = this.form.checkTime[0]
          formData.endTime = this.form.checkTime[1]
      }
      this.$http.get(apis.getGateRecord, { params: formData }).then((res) => {
        if (res.data.code === 0) {
            console.log(res.data.rows, '闸机记录');
            this.tableData = res.data.rows;
            this.form.total = res.data.total
        }
      });
    },
    getCheckType() {
        this.$http.get(apis.getCheckType).then((res) => {
            if (res.data.code === 0) {
                // console.log(res.data.data);
                this.checkType = res.data.data
            }
        })
    },
  },
  activated() {
      this.searchEvent()
      this.getCheckType()
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.operation {
  padding: 0 20px;
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}
</style>
